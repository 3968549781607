import React, { useContext, useEffect, useState } from "react";
import { Navigate,useParams } from 'react-router-dom'
import { db } from "../../../firebase";

import { firebaseAuth } from '../../../providers/AuthProvider';
import '../../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { GetDeviceId } from "../../../Constants";
import moment from "moment";
const Sessions = () => {
  const [ eventData, setEventData ] = useState(null);
  const [eventReportData, setEventReportData] = useState(null);
  const [regData, setRegData] = useState(null);
  const { user } = useContext(firebaseAuth);
  const { id } = useParams();

  const formatTime = (t) => {
    if (t) {
      let tm = t.split(":");
      let h = +tm[0];
      let m = tm[1];
      let ampm = "AM";

      if (h >= 12) {
        ampm = "PM";
      }
      if (h > 12) {
        h -= 12;
      }
      return h + ":" + m + " " + ampm;
    } else {
      return "";
    }
  }
  const getCount = (passcode,status,isConfirmed) => {
    if (regData) {
      let count = regData.filter(o => o.passcode === passcode && o.status === status).length;
      return count;
    }
    return 0;
  }
  const loadSessionReport = async () => {
    let tmpEventData = eventData;
    if(!tmpEventData || tmpEventData.id !== id) {
      tmpEventData = (await db.collection("events").doc(id).get()).data();
      setEventData(tmpEventData);
      localStorage.setItem("eventData", JSON.stringify(tmpEventData));
    }
    if (tmpEventData.allowCapacity && tmpEventData.capacityType == "session") {

    let regDataLookup = await db.collection("registrations").where("eventId", "==", tmpEventData.id).get();
    let tmpRegData = [];
    regDataLookup.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      tmpRegData.push(data);
    });
    setRegData(tmpRegData);
    var updateSessionAvailability = await firebase.functions().httpsCallable("app/updateSessionAvailability");
    let sessionAvailability = await updateSessionAvailability({
      sessions: tmpEventData.sessions,
      eventId: tmpEventData.id,
      skipReserved: true,
      skipSessionCheck: true,
      deviceId: GetDeviceId()
    });
    let eData = tmpEventData;

    eData.sessions = sessionAvailability.data.sessions;
    eData.sessionItems.forEach((item, index) => {
      let session = eData.sessions.filter(o => o.itemIndex === index); // this doesn't seem to do anything @Mike
      if (session) {
        let days = {};
        session.forEach((s, i) => {
          let d = s.startTime.split(" ")[0];
          let t = s.startTime.split(" ")[1];
          if (!days[d]) days[d] = [];
          let dCheck = days[d].find(o => o.time === t);
          if (!dCheck)
            days[d].push({ date: d, time: t, startTime: s.startTime, items: [{ itemIndex: s.itemIndex, available: s.available, reserved: s.reserved, startTime: s.startTime, passcode: s.passcode, id: s.id }] });
          else {
            dCheck.items.push({ itemIndex: s.itemIndex, available: s.available, reserved: s.reserved, startTime: s.startTime, passcode: s.passcode, id: s.id });
          }
        });
        item.sessions = days;
      }
    });
    setEventReportData(eData);
  }
  }
  useEffect(() => {
      loadSessionReport();
    
  }, []);
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Session Report</h1>
      <div className="py-3"></div>
      {eventData?.allowCapacity && eventData?.capacityType == "session" && <>
        {eventReportData?.sessionItems?.map((item, index) => {
          return <div key={index}>
            <h4>{item.name}</h4>
            <div className="py-2"></div>
            {Object.keys(item?.sessions)?.map((s, index) => {
              return <div key={index}>
                <h5>{moment(s).format("dddd MMMM Do YYYY")}</h5>
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Registered</th>
                      <th>Avaialable</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.sessions[s]?.map((session, index) => {
                      return (
                        <tr key={index}>
                          <td>{formatTime(session.time)}</td>
                          <td>{session.items.filter(o => !o.available).length}</td>
                          <td>{session.items.filter(o => o.available).length}</td>
                          <td>{session.items.length}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="py-2"></div>
              </div>
            })}
          </div>
        })}
      </>}
      {eventData?.allowMultiUsePasscodes && eventData?.multiUsePasscodes?.length > 0 && <>
        <h3>Passcodes</h3>

        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Code</th>
              <th>Pending</th>
              <th>Registered</th>
              <th>Checked-In</th>
              <th>Waitlist</th>
              <th>Cancelled</th>
            </tr>
          </thead>
          <tbody>
            {eventData?.multiUsePasscodes?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.passcode}</td>
                  <td>{getCount(item.passcode,"Pending")}</td>
                  <td>{getCount(item.passcode,"Registered")}</td>
                  <td>{getCount(item.passcode,"Checked-In")}</td>
                  <td>{getCount(item.passcode,"Waitlist")}</td>
                  <td>{getCount(item.passcode,"Cancelled")}</td>
                </tr>

              )
            })}
          </tbody>
        </table>
        <div className="py-2"></div>

      </>
      }
    </div>
  );
};
export default Sessions;
